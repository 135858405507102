import Vue from 'vue';
import Router from 'vue-router';
import Top from './../components/Top';
import Goods from './../components/Goods';
import AppStore from './../components/AppStore';
import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Top
      // redirect: '/goods'
    },
    {
      path: '/goods',
      name: 'goods',
      component: Goods
    },
    {
      path: '/appstore',
      name: 'appstore',
      component: AppStore
    },
  ]
});