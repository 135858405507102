<template>
  <v-row cols="12" justify="center">
    <v-chip-group multiple v-model="selectedChips" @change="change">
      <v-chip v-for="chip in chips" :key="chip" active-class="red--text" small class="ma-0">
        <strong>{{ chip }}</strong>
      </v-chip>
    </v-chip-group>
  </v-row>
</template>

<script>
export default {
  name: "CategoryChips",
  props: {
    chips: Array,
  },
  data: () => {
    return {
      selectedChips: [],
    };
  },
  async mounted() {
    this.selectedChips = this.chips.map((_, index) => index);
  },
  methods: {
    change() {
      const categories = this.selectedChips.map(index => this.chips[index]);
      this.$emit("change", categories);
    }
  }
};
</script>
