import Vue from 'vue'
import App from './App.vue'
import VueLazyload from 'vue-lazyload'
import vuetify from './plugins/vuetify';
import router from './routers';
import titleMixin from './mixin/title'

Vue.use(VueLazyload, {
  preLoad: 1.0, //1.3,
  error: 'https://dummyimage.com/130x120/ccc/999.png&text=Not+Found',
  loading: 'https://dummyimage.com/130x120/dcdcdc/999.png&text=Now loading',
  attempt: 1
})

Vue.mixin(titleMixin)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify: vuetify,
  render: h => h(App),
}).$mount('#app')
