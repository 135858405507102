<template>
  <v-sheet justify="center" align="center" class="py-4" :class="mirror ? 'red white--text' : ''">
    <div class="promo-header title" :class="mirror ? 'white--text' : 'red--text'">{{header}}</div>
    <v-col dense no-gutters cols="12" sm="10" md="7" lg="6" xl="6">
      <v-row>
        <v-col cols="5" v-if="!mirror">
          <v-container fluid fill-height>
            <img v-lazy="image" width="100%" />
          </v-container>
        </v-col>
        <v-col cols="7" class="pa-2">
          <v-container fluid fill-height>{{description}}</v-container>
        </v-col>
        <v-col cols="5" v-if="mirror">
          <v-container fluid fill-height>
            <img v-lazy="image" width="100%" />
          </v-container>
        </v-col>
      </v-row>
    </v-col>
  </v-sheet>
</template>

<script>
export default {
  name: "TopPromoTutorial",
  props: {
    header: String,
    image: String,
    description: String,
    mirror: {
      type: Boolean,
      default: false
    }
  },
  methods: {}
};
</script>
<style scoped>
.promo-header {
  font-weight: bold;
  text-align: center;
}
</style>
