<template>
  <v-row class="ma-0 mx-n3">
    <v-col class="pa-0">
      <v-sheet>
        <v-toolbar flat color="white" height="40">
          <v-btn outlined small class="ma-0 pa-0" color="grey darken-2" @click="setToday">今日</v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon class="ma-0 pa-0" small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="450">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :now="today"
          type="month"
          locale="ja-jp"
          :event-height="14"
          :show-month-on-first="false"
          :day-format="date => date.day"
          @click:event="showEvent"
          @click:more="scrollToDay"
          @click:date="scrollToDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title class="caption">{{selectedEvent.name}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row dense no-gutters>
                <span>{{selectedEvent.details}}</span>
              </v-row>
              <v-row justify="center">
                <v-img
                  width="100px"
                  max-width="150"
                  contain
                  :src="((e) => e.goods === undefined ? '' : e.goods.imageUrl)(selectedEvent)"
                ></v-img>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                class="text-capitalize"
                @click="selectedOpen = false"
              >閉じる</v-btn>
              <v-btn
                text
                color="primary"
                class="text-capitalize"
                @click="selectedOpen = false"
              >Amazonで見る</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import goTo from "vuetify/es5/services/goto";

export default {
  props: {
    goodsList: Array
  },
  data: () => ({
    focus: "",
    start: null,
    end: null,
    today: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1"
    ]
  }),
  watch: {
    goodsList: function() {
      this.events = this.makeEvents();
    }
  },
  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startYear = this.yearFormatter(start);
      return startYear;
    },
    yearFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        year: "numeric",
        month: "narrow"
      });
    }
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    scrollToDay(data) {
      const date = data.date;
      if (this.goodsList.some(goods => goods.date == date)) {
        goTo("#goods-" + date);
      }
    },
    makeEvents() {
      const events = [];
      for (let goods of this.goodsList) {
        const dateComponents = goods.date.split("-");
        const year = parseInt(dateComponents[0], 10);
        const month = parseInt(dateComponents[1], 10);
        const day = parseInt(dateComponents[2], 10);
        const date = new Date(year, month - 1, day);
        events.push({
          goods: goods,
          name: goods.animeTitle,
          details: goods.goodsTitle,
          start: this.formatDate(date, false),
          end: this.formatDate(date, false),
          color: this.colors[this.rnd(0, this.colors.length - 1)]
        });
      }
      return events;
    },
    updateRange({ start, end }) {
      if (this.start == null || this.start.year != start.year || this.start.month != start.month) {
        this.$emit("change", start.year, start.month);
      }
      this.start = start;
      this.end = end;
      this.events = this.makeEvents();
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${a.getMonth() +
            1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    }
  }
};
</script>

<style scoped>
.v-calendar-weekly >>> .v-calendar-weekly__day-label {
  margin: 0;
}
.v-calendar-weekly >>> .v-btn--fab.v-size--small {
  height: 20px !important;
}
.v-calendar-weekly >>> .v-event {
  font-size: 8px;
  margin-left: 1px;
}
.v-calendar-weekly >>> .v-event-more {
  font-size: 8px;
  margin-left: 1px;
}
.v-calendar-weekly >>> .v-btn--round {
  border-radius: 4px;
}
</style>
