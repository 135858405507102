export default {
  mounted() {
    let { title, description } = this.$options
    if (title) {
      title = typeof title === 'function' ? title.call(this) : title
      document.title = `あにふぉりお - ${title}`
    }
    if (description) {
      description = typeof description === 'function' ? description.call(this) : description
      document.description = description;
    }
  },
  metaInfo() {
    let { title, description } = this.$options
    return {
      meta: [
        { name: 'description', content: description },
        { property: 'twitter:card', content: 'summary' },
        { property: 'twitter:title', content: title },
        { property: 'twitter:site', content: '@anonymous' },
        { property: 'twitter:creator', content: '@anonymous' },
        { property: 'twitter:description', content: description },
        {
          property: 'twitter:image',
          content: 'https://anifolio.app/ogp.png',
        },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: location.href },
        {
          property: 'og:image',
          content: 'https://anifolio.app/ogp.png',
        },
      ],
    };
  },
}