<template>
  <v-app style="overflow: hidden;">
    <v-content>
      <div id="app-content"></div>
      <router-view />
    </v-content>
    <v-footer dark padless>
      <v-container fluid class="pa-0">
      <v-card flat tile fluid class="red white--text text-center">
        <v-card-text>
          <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" :href="icon.url" icon>
            <v-icon size="24px">{{ icon.icon }}</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-text class="white--text pt-0">
          あにふぉりおはアニメファン向けの情報収集兼管理アプリです。
アニメや声優ラジオ、アニメ特番などの動画や番組表、ニュースをチェックしたり、今までに見たアニメを記録することでどれくらいのアニメを見たのかをグラフで確認できたりします。<br>
<a href="https://apple.co/2X1fyZB">iOS</a> / <a href="https://bit.ly/39C1MPR">Android</a>版を提供しています。
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          © {{ new Date().getFullYear() }} —
          <strong>Anifolio</strong>
        </v-card-text>
      </v-card>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    icons: [
      { icon: "fab fa-twitter", url: "https://twitter.com/anifolio" },
      { icon: "fab fa-apple", url: "https://apple.co/2X1fyZB" },
      { icon: "fab fa-android", url: "https://bit.ly/39C1MPR" },
    ]
  })
};
</script>
<style scoped>
.v-application .v-footer a {
  color: white;
}
</style>
