<template>
  <div>
    <Top />
  </div>
</template>

<script>
import Top from "./Top";
export default {
  name: "AppStore",
  title: 'トップ',
  description: 'あにふぉりおはアニメファン向けの情報収集兼管理アプリです。アニメや声優ラジオ、アニメ特番などの動画や番組表、ニュースをチェックしたり、今までに見たアニメを記録することでどれくらいのアニメを見たのかをグラフで確認できたりします。',
  props: {},
  methods: {},
  components: {
      Top
  },
  mounted: () => {
    if ((navigator.userAgent.toLowerCase().indexOf("iphone") > -1) || (navigator.userAgent.toLowerCase().indexOf("ipod") > -1) || (navigator.userAgent.toLowerCase().indexOf("ipad") > -1)) {
        window.location.href = "https://apple.co/2X1fyZB";
    } else {
        window.location.href = "https://bit.ly/39C1MPR";
    }
  }
};
</script>

<style scoped>
.top-block {
  background-color: #f44336;
}
</style>