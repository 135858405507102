<template>
  <v-card class="pa-0 ma-0" @click.stop="buyAmazon(goods.url)">
    <v-row dense no-gutters>
      <v-col cols="4" align="center">
        <v-row cols="12" dense no-gutters justify="center">
          <!-- <v-avatar class="ma-3" size="100" tile> -->
          <img style="width: 100px; height: 100px; object-fit: contain" v-lazy="goods.imageUrl" >
          <img v-lazy="'https://ir-jp.amazon-adsystem.com/e/ir?t=anifolio-22&language=ja_JP&l=li3&o=9&a=' + goods.asin" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
          <!-- </v-avatar> -->
        </v-row>
        <v-row cols="12" justify="center" class="caption grey--text text--darken-1">{{goods.date}}</v-row>
      </v-col>
      <v-col cols="8" class="px-2 pt-1 caption">
        <v-row cols="12" dense no-gutters>{{goods.animeTitle}}</v-row>
        <v-row cols="12" class="px-2 font-weight-bold caption">{{goods.goodsTitle}}</v-row>
        <v-row cols="12" dense no-gutters>
          <v-chip outlined x-small pill>{{goods.type}}</v-chip>
        </v-row>
        <v-chip
          @click="buyAmazon(goods.url)"
          color="red"
          text-color="white"
          class="my-2"
          small
        >Amazonで見る</v-chip>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "GoodsItem",
  props: {
    goods: Object,
  },
  methods: {
    buyAmazon(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
