<template>
  <div class="goods">
    <v-container>
      <!-- <v-subheader>4月に発売するグッズ</v-subheader> -->
      <!-- <v-row cols="12" justify="center">
        <v-icon class="px-2" @click='stepMonthAndLoad(-1)' :disabled='!canStepMonth(-1)'>mdi-chevron-left</v-icon>
        <v-spacer></v-spacer>
        {{year}}年{{month}}月
        <v-spacer></v-spacer>
        <v-icon class="px-2" @click='stepMonthAndLoad(1)' :disabled='!canStepMonth(1)'>mdi-chevron-right</v-icon>
      </v-row>-->
      <CategoryChips :chips="chips" @change="changeFilter" />

      <GoodsCalendar :goodsList="filteredGoodsList" @change="changeMonth" />
      <v-row v-if="goodsList == null">
        <v-col cols="12" v-for="(_, index) in [1, 2, 3, 4, 5]" :key="index">
          <v-skeleton-loader type="list-item-avatar-three-line" class="mx-auto"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          v-for="goods of filteredGoodsList"
          :key="goods.asin"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="pa-1"
        >
          <v-card-text align="center" class="pa-1" v-if="goods.showDate">
            <v-chip :id="'goods-'+goods.date" outlined x-small pill>{{goods.date}}</v-chip>
          </v-card-text>
          <GoodsItem :goods="goods" />
        </v-col>
      </v-row>
    </v-container>
    <FloatingScrollToTopButton />
  </div>
</template>

<script>
import GoodsItem from "./GoodsItem";
import FloatingScrollToTopButton from "./FloatingScrollToTopButton";
import GoodsCalendar from "./GoodsCalendar";
import CategoryChips from "./CategoryChips";
export default {
  name: "Goods",
  title: 'グッズ情報',
  components: {
    GoodsItem,
    FloatingScrollToTopButton,
    GoodsCalendar,
    CategoryChips
  },
  data: () => {
    return {
      goodsList: [],
      filteredGoodsList: [],
      goodsListCache: {},
      chips: ["MUSIC", "BD", "DVD", "TOY", "BOOK", "GAME"],
      year: (new Date()).getFullYear(),
      month: (new Date()).getMonth() + 1,
      MAX_YEAR: 3020,
      MIN_YEAR: 2000,
      MAX_MONTH: 12
    };
  },
  async mounted() {
    await this.load(this.year, this.month);
    this.filteredGoodsList = this.goodsList;
  },
  methods: {
    async load(year, month) {
      this.goodsList = null;
      if (this.canLoad(year, month)) {
        this.goodsList = await this.fetchGoods(year, month);
      } else {
        this.goodsList = [];
      }
      this.changeFilter(this.chips);
      this.year = year;
      this.month = month;
    },
    async fetchGoods(year, month) {
      const cacheKey = `${year}-${month}`;
      if (this.goodsListCache[cacheKey] != null) {
        return this.goodsListCache[cacheKey];
      }
      const response = await fetch(
        "https://firebasestorage.googleapis.com/v0/b/anifolio-9ea68.appspot.com/o/public%2Fgoods%2F" +
          year +
          "%2F" +
          month +
          ".json?alt=media",
        { mode: "cors" }
      );
      // const response = await fetch("./data.json", { mode: "cors" });
      const goodsList = await response.json();
      goodsList.forEach(goods => {
        goods["url"] =
          "https://www.amazon.co.jp/gp/product/" +
          goods.asin +
          "/?tag=anifolio-22";
        // goods["imageUrl"] = "https://dummyimage.com/100x100/555555/fff.png";
        goods['imageUrl'] = 'https://ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=' + goods.asin + '&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=anifolio-22&language=ja_JP';
        // goods['dateWithWeekday'] = goods.date;
        goods["date"] = goods.date.split("(")[0];
      });
      this.goodsListCache[cacheKey] = goodsList;
      return goodsList;
    },
    changeFilter(selectedChips) {
      this.filteredGoodsList = this.goodsList.filter(goods =>
        selectedChips.includes(goods.type)
      );
      var date = null;
      this.filteredGoodsList.forEach(goods => {
        if (date != goods.date) {
          goods["showDate"] = true;
          date = goods.date;
        } else {
          goods["showDate"] = false;
        }
      });
    },
    async stepMonthAndLoad(offset) {
      this.stepMonth(offset);
      await this.load(this.year, this.month);
    },
    async changeMonth(year, month) {
      await this.load(year, month);
    },
    addMonth(offset) {
      let year = this.year;
      let month = this.month + offset;
      if (month < 1) {
        month = 12;
        year -= 1;
      } else if (month > 12) {
        month = 1;
        year += 1;
      }
      year = Math.max(Math.min(this.MAX_YEAR, year), this.MIN_YEAR);
      if (year == this.MAX_YEAR && month > this.MAX_MONTH) {
        month = this.MAX_MONTH;
      }
      return { year: year, month: month };
    },
    stepMonth(offset) {
      const result = this.addMonth(offset);
      this.year = result.year;
      this.month = result.month;
    },
    canStepMonth(offset) {
      const result = this.addMonth(offset);
      return this.year != result.year || this.month != result.month;
    },
    canLoad(year, month) {
      if (year == this.MAX_YEAR) {
        return month <= this.MAX_MONTH;
      }
      return this.MIN_YEAR <= year && year <= this.MAX_YEAR;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.v-icon:disabled {
  opacity: 0.3;
}
</style>
