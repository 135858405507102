<template>
  <div>
    <v-row dense no-gutters cols="12">
      <v-col
        cols="12"
        color="red"
        class="top-block white--text py-4 px-n5"
        justify="center"
        align="center"
      >
        <div class="pt-8">アニメのニュース・番組表・視聴記録なら</div>
        <!-- <v-img width="60%" src="../assets/logo.svg"></v-img> -->
        <v-img width="200px" src="@/assets/promo/logo_text.png"></v-img>
        <v-col cols="12" sm="10" md="7" lg="6" xl="6">
          <v-img class="mt-6" src="@/assets/promo/promo_screenshots.png"></v-img>
        </v-col>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="5" sm="4" md="3" lg="2" xl="2">
        <a href="https://apple.co/2X1fyZB">
          <v-img src="@/assets/promo/dl_ios.png"></v-img>
        </a>
      </v-col>
      <v-col cols="5" sm="4" md="3" lg="2" xl="2">
        <a href="https://bit.ly/39C1MPR">
          <v-img src="@/assets/promo/dl_android.png"></v-img>
        </a>
      </v-col>
    </v-row>
    <TopPromoTutorial
      header="アニメの視聴記録"
      :image="require('@/assets/promo/anime_detail.png')"
      description="アニメごとに「視聴済み」、「視聴中止」などの視聴ステータスを設定できます。また、何話まで見たのかを記録することもできます。"
    />
    <TopPromoTutorial
      header="通知設定で見逃し防止"
      :image="require('@/assets/promo/notification.png')"
      description="アニメや声優ラジオ、アニメ特番などの放送直前に通知を受け取れます。毎回通知設定にすると最終回まで通知してくれます。"
      mirror="true"
    />
    <TopPromoTutorial
      header="アニメのトレンドニュース"
      :image="require('@/assets/promo/news.png')"
      description="毎日、朝昼晩にアニメ関連のニュースが更新されます。プッシュ通知を受け取ることもできます。"
    />
    <TopPromoTutorial
      header="番組表で気になる番組を発見"
      :image="require('@/assets/promo/program.png')"
      description="アニメ、声優ラジオ、声優が出ている特番の番組表が見れます。通知設定もできます。"
      mirror="true"
    />
    <TopPromoTutorial
      header="あなたのアニメ好き度をチェック"
      :image="require('@/assets/promo/graph.png')"
      description="視聴記録をつけると今までアニメをどれくらい見たのかなどがグラフで表示されます。"
    />

    <v-sheet justify="center" align="center" class="pa-4">
      <div class="title red--text font-weight-bold">あにふぉりおはあなたのアニメライフをサポートします！</div>
      <v-row justify="center">
        <v-col cols="5" sm="4" md="3" lg="2" xl="2">
          <a href="https://apple.co/2X1fyZB">
            <v-img src="@/assets/promo/dl_ios.png"></v-img>
          </a>
        </v-col>
        <v-col cols="5" sm="4" md="3" lg="2" xl="2">
          <a href="https://bit.ly/39C1MPR">
            <v-img src="@/assets/promo/dl_android.png"></v-img>
          </a>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import TopPromoTutorial from "./TopPromoTutorial";
export default {
  name: "Top",
  title: 'トップ',
  description: 'あにふぉりおはアニメファン向けの情報収集兼管理アプリです。アニメや声優ラジオ、アニメ特番などの動画や番組表、ニュースをチェックしたり、今までに見たアニメを記録することでどれくらいのアニメを見たのかをグラフで確認できたりします。',
  props: {},
  methods: {},
  components: {
    TopPromoTutorial
  }
};
</script>

<style scoped>
.top-block {
  background-color: #f44336;
}
</style>