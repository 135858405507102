<template>
  <transition name="fade">
    <v-btn
      fixed
      fab
      bottom
      right
      color="#f4433688"
      style="bottom: 100px"
      v-show="this.isShowUp"
      @click="$vuetify.goTo(0)"
    >
      <v-icon color="white">mdi-chevron-up</v-icon>
    </v-btn>
  </transition>
</template>

<script>
export default {
  name: "FloatingScrollToTopButton",
  data: () => {
    return {
      isShowUp: false
    };
  },
  async mounted() {
    window.addEventListener("scroll", this.onScreenEvent);
    window.addEventListener("resize", this.onScreenEvent);
    window.addEventListener("load", this.onScreenEvent);
  },
  methods: {
    onScreenEvent() {
      this.isShowUp = window.pageYOffset >= 32;
    }
  }
};
</script>
